<template>
  <div v-if="dynamicWithForecast" style="width: 1000px">
    <HistogramAndGraph :data="dynamicWithForecast" :options="{}" />
    <d3-legend :items="legend" />
  </div>
</template>

<script>
import HistogramAndGraph from '../../d3new/HistogramWithGraph.vue'
import D3Legend from '../old/D3Legend.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'

const template = {
  i: 0, // Индекс
  w: 550, // Ширина
  h: 350, // Высота
  aw: false, // Автоматическая ширина
  t: 'unknown', // Тип
  m: { l: 20, t: 20, r: 20, b: 20 }, // Границы
  d: [], // Данные
  o: {}, // Опции
}

export default {
  data() {
    return {
      legend: [
        {
          id: 1,
          name: 'Потери ожидаемой продолжительности жизни, лет',
          color: 'steelblue',
        },
        {
          id: 2,
          name: 'Стандартизованный коэффициент смертности, чел. на 100 тыс. населения',
          color: '#69b3a2',
        },
      ],
    }
  },
  components: {
    HistogramAndGraph,
    D3Legend,
  },
  mounted() {
    this.modifyPlot({
      type: 'sdr-and-dyn',
      item: { w: 950, h: 650, m: { l: 60, t: 20, r: 60, b: 40 } },
    })
  },
  watch: {
    dictionaries: {
      handler(v) {
        let xValues = []
        if (v && v.years && v.years.length > 0) {
          xValues = v.years.map(d => ({ v: +d.value, r: true }))
          xValues.push(
            ...[...Array(3)].map((_, i) => ({
              v: Math.max(...v.years.map(b => b.value)) + i + 1,
              r: false,
            }))
          )
        }
        this.modifyPlot({
          type: 'sdr-and-dyn',
          item: { o: { xValues } },
        })
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      loadSdrAndDynData: 'ly/loadSdrAndDynData',
    }),
    ...mapMutations({
      modifyPlot: 'ly/MODIFY_UNIQUE_PLOT',
    }),
    calculateCoefficients(data) {
      let s1, s2, s3, s4, n
      s1 = s2 = s3 = s4 = 0
      n = data.length
      for (let i = 0; i < data.length; i++) {
        s1 += +data[i].year * +data[i].value
        s2 += +data[i].value
        s3 += +data[i].year
        s4 += +data[i].year * +data[i].year
      }
      const a = (n * s1 - s2 * s3) / (n * s4 - s3 * s3)
      const b = (s2 - a * s3) / n
      return { a, b }
    },
  },
  computed: {
    ...mapGetters({
      params: 'ly/getParams',
      getSdrAndDynPlot: 'ly/getSdrAndDynPlot',
      getSdrAndDynData: 'ly2/getDynamicData',
      dictionaries: 'ly/getDictionaries',
    }),
    getSdrAndDynPlot2() {
      if (!this.getSdrAndDynData) return
      let xValues = []
      xValues = this.getSdrAndDynData.dynamic.map(d => ({ v: +d.year, r: true }))
      xValues.push(
        ...[...Array(1)].map((_, i) => ({
          v: Math.max(...this.getSdrAndDynData.dynamic.map(b => +b.year)) + i + 1,
          r: false,
        }))
      )
      return {
        ...template,
        d: this.getSdrAndDynData || [],
        o: { xValues },
        w: 1000,
        h: 2200,
      }
    },
    dynamicWithForecast() {
      if (
        !this.getSdrAndDynPlot2 ||
        this.getSdrAndDynPlot2.length === 0 ||
        !this.getSdrAndDynPlot2.d.dynamic
      ) {
        return null
      }
      let data = JSON.parse(JSON.stringify(this.getSdrAndDynPlot2))
      data.d.dynamic = data.d.dynamic.map(d => {
        d.value = +d.value || 0
        return d
      })
      data.d.sdr = data.d.sdr.map(d => {
        d.value = +d.value || 0
        return d
      })
      if (data.o.xValues) {
        const { a, b } = this.calculateCoefficients(data.d.dynamic)
        const { a: a2, b: b2 } = this.calculateCoefficients(data.d.sdr)
        data.o.xValues.forEach(d => {
          if (!d.r && !data.d.dynamic.find(b => b.year === d.v)) {
            data.d.dynamic.push({
              value: a * d.v + b > 0 ? a * d.v + b : 0,
              year: d.v,
            })
          }
          if (!d.r && !data.d.sdr.find(b => b.year === d.v)) {
            data.d.sdr.push({
              value: a2 * d.v + b2 > 0 ? a2 * d.v + b2 : 0,
              year: d.v,
            })
          }
        })
      }
      return data
    },
  },
}
</script>
