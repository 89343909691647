<template>
  <div class="histogram-and-graph" :style="containerStyle">
    <SvgSizeTemplate :width="width" :height="height" :margin="margin" />
  </div>
</template>

<script>
import {
  scaleBand,
  scaleLinear,
  axisBottom,
  axisLeft,
  axisRight,
  line as d3Line,
  min,
  max,
  format,
  select,
} from 'd3'
import d3PlotMixin from './d3PlotMixin'
import d3WatchAndMountMixin from './d3WatchAndMountMixin'
import SvgSizeTemplate from './SvgSizeTemplate.vue'
import { mapActions } from 'vuex'

export default {
  mixins: [d3PlotMixin, d3WatchAndMountMixin],
  components: {
    SvgSizeTemplate,
  },
  props: {
    height: {
      type: Number,
      default: 600,
    },
    width: {
      type: Number,
      default: 1050,
    },
    data: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
  },
  mounted() {},
  data() {
    return {
      forecastLength: 1,
      textMargin: 10,
      margin: {
        left: 80,
        top: 40,
        right: 80,
        bottom: 40,
      },
      lastValues: [...new Array(this.data.d.dynamic.length)].map(() => 0),
    }
  },
  computed: {
    containerStyle() {
      return `height: ${this.height}px;width: ${this.width}px;`
    },
    sdr() {
      return this.prepareData('sdr')
    },
    points() {
      return this.prepareData('dynamic')
    },
    color() {
      return this.options.color ? this.options.color : 'steelblue'
    },
    color2() {
      return this.options.color2 ? this.options.color2 : '#69b3a2'
    },
    format() {
      const { valueType = 'decimal' } = this.options
      if (valueType === 'decimal') {
        return format('.3~r')
      } else if (valueType === 'integer') {
        return a => parseInt(a)
      } else if (valueType === 'percent') {
        return format('.3~%')
      } else {
        throw Error('Заданный формат преобразования не поддерживается')
      }
    },
    xScale() {
      return scaleBand()
        .domain(this.points.map(d => d.x))
        .range([0, this.mainPlotAreaWidth])
        .paddingInner(0.6)
        .paddingOuter(0.3)
    },
    yScale() {
      return scaleLinear()
        .domain([0, max(this.points.map(d => d.y)) * 1.3 || 1])
        .range([this.mainPlotAreaHeight, 0])
    },
    minSDR() {
      return (
        min(this.sdr.map(d => d.y)) -
          (max(this.sdr.map(d => d.y)) - min(this.sdr.map(d => d.y))) * 0.2 || 0
      )
    },
    maxSDR() {
      return (
        max(this.sdr.map(d => d.y)) +
          (max(this.sdr.map(d => d.y)) - min(this.sdr.map(d => d.y))) * 0.2 || 1
      )
    },
    yScaleSDR() {
      return scaleLinear().domain([this.minSDR, this.maxSDR]).range([this.mainPlotAreaHeight, 0])
    },
    lineData() {
      const { sdr, xScale, yScaleSDR } = this
      return sdr.map(d => [xScale(d.x) + xScale.bandwidth() / 2, yScaleSDR(d.y)])
    },
  },
  watch: {
    [`data.d.dynamic`]: {
      handler(v, p) {
        this.lastValues = p.map(d => d.value)
      },
    },
  },
  methods: {
    prepareData(dataType) {
      const { data, forecastLength } = this
      let j = 0
      let v = data.d[dataType]
        .filter(d => d.id || j++ < forecastLength)
        .map(d => ({
          x: d.year,
          y: d.value,
          isForecast: !d.id,
        }))
      return v
    },
    render() {
      this.renderYears()
      this.renderXAxis()
      this.renderYAxisLeft()
      this.renderYAxisRight()
      this.renderHistoricalLine()
      this.renderForecastLine()
      this.renderDots()
    },
    renderHistoricalLine() {
      this.renderLine(false, 'historical-line')
    },
    renderForecastLine() {
      this.renderLine(true, 'forecast-line')
    },
    enterLine(line, isForecast, className, initialLineData) {
      const { color2 } = this
      line
        .append('path')
        .classed(`${className}`, true)
        .attr('d', () => d3Line()(initialLineData))
        .attr('stroke-width', 1.5)
        .attr('stroke', color2)
        .attr('stroke-dasharray', () => (isForecast ? '6' : 'none'))
        .attr('fill', 'none')
        .call(this.updateLine)
    },
    updateLine(line) {
      line
        .transition('')
        .duration(this.animationDuration)
        .attr('d', d => d3Line()(d))
    },
    getGraphLineData(isForecast) {
      const { lineData, forecastLength } = this
      const forcastPosition = lineData.length - forecastLength
      return isForecast ? lineData.slice(forcastPosition - 1) : lineData.slice(0, forcastPosition)
    },
    renderLine(isForecast, className) {
      const { svg, enterLine, updateLine, getGraphLineData, minSDR } = this
      const graphLineData = getGraphLineData(isForecast)
      const initialLineData = graphLineData.map(d => [d[0], minSDR])
      /*
        graphLineData в методе d3 data обернтуто в еще один массив,
        чтобы данные для линии не разобрались на точки, а остались одним набором
      */
      svg
        .select('g.content')
        .selectAll(`path.${className}`)
        .data([graphLineData])
        .join(enter => enterLine(enter, isForecast, className, initialLineData), updateLine)
    },
    enterDot(dot) {
      const { xScale, yScaleSDR, updateDot, color2, setTooltip, format } = this
      dot
        .append('circle')
        .attr('cx', d => xScale(d.x) + xScale.bandwidth() / 2)
        .attr('cy', () => yScaleSDR(0))
        .attr('r', 5)
        .attr('fill', color2)
        .on('mouseover.internal.sdr', function (d) {
          select(this).attr('opacity', 0.7)
          setTooltip({
            show: true,
            top: this.getBoundingClientRect().top + window.pageYOffset,
            left: this.getBoundingClientRect().left + window.pageXOffset,
            width: 100,
            title: 'Значение SDR',
            text: format(d.y),
          })
        })
        .on('mouseout.internal.sdr', function () {
          select(this).attr('opacity', 1)
          setTooltip({
            show: false,
          })
        })
        .call(updateDot)
    },
    updateDot(dot) {
      const { yScaleSDR, animationDuration } = this
      dot
        .transition('')
        .duration(animationDuration)
        .attr('cy', d => yScaleSDR(d.y))
    },
    renderDots() {
      const { svg, sdr, enterDot, updateDot } = this
      svg.select('g.content').selectAll('circle').data(sdr).join(enterDot, updateDot)
    },
    renderYears() {
      const { points, svg, xScale } = this
      svg
        .select('g.content')
        .selectAll('g.bar')
        .data(points)
        .join(
          enter =>
            enter
              .append('g')
              .classed('bar', true)
              .attr('transform', ({ x }) => `translate(${xScale(x)},0)`)
              .call(this.rectPrepare)
              .call(this.rectAnimation),
          update => update.call(this.rectAnimation)
        )
    },
    renderYAxisLeft() {
      const { svg, animationDuration, yScale, color } = this
      svg
        .select('g.y-axis-left')
        .attr('color', color)
        .transition('')
        .duration(animationDuration)
        .call(axis => {
          axis.call(axisLeft().scale(yScale).tickSizeOuter(0))
          axis.selectAll('text').attr('color', '#555')
        })
    },
    renderYAxisRight() {
      const { svg, animationDuration, yScaleSDR, color2 } = this
      svg
        .select('g.y-axis-right')
        .attr('color', color2)
        .transition('')
        .duration(animationDuration)
        .call(axis => {
          axis.call(axisRight().scale(yScaleSDR).tickSizeOuter(0))
          axis.selectAll('text').attr('color', '#555')
        })
    },
    renderXAxis() {
      const { svg, animationDuration, xScale } = this
      svg
        .select('g.x-axis-bottom')
        .transition('')
        .duration(animationDuration)
        .call(axisBottom().scale(xScale))
    },
    rectPrepare(s) {
      const { xScale, yScale, textMargin } = this
      s.append('rect')
        .attr('x', 0)
        .attr('y', () => yScale(0))
        .attr('opacity', d => (d.isForecast ? 0.3 : 1))
        .attr('width', xScale.bandwidth())
        .on('mouseover.internal', () => {})
        .on('mouseout.internal', () => {})
      s.append('text')
        .attr('x', xScale.bandwidth() / 2)
        .attr('y', () => yScale(0) - textMargin)
        .attr('text-anchor', 'middle')
        .attr('font-weight', '450')
        .attr('fill', 'grey')
      return s
    },
    rectAnimation(s) {
      const {
        animationDuration,
        color,
        mainPlotAreaHeight,
        format,
        yScale,
        lastValues,
        textMargin,
      } = this
      s.select('g.bar rect')
        .transition('rectAnimation')
        .duration(animationDuration)
        .attr('y', d => yScale(d.y))
        .attr('height', d => mainPlotAreaHeight - yScale(d.y))
        .attr('fill', color)
        .style('stroke', color)
      s.select('g.bar text')
        .transition('textAnimation')
        .duration(animationDuration)
        .attr('y', d => yScale(d.y) - textMargin)
        .textTween((d, i) => t => {
          return format(lastValues[i] + (d.y - lastValues[i]) * t)
        })
      return s
    },
    ...mapActions({ setTooltip: 'tt/setTooltip' }),
  },
}
</script>
