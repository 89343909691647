<template>
  <div>
    <TopMenu />

    <ParamsPanel>
      <SelectRegion />
      <SelectReason />
      <SelectSex />
      <SelectAge />
      <SelectPopulationGroup />
    </ParamsPanel>

    <MainContent>
      <PageHeader title="Динамика показателей смертности" />
      <DynamicContent />
    </MainContent>
  </div>
</template>

<script>
import TopMenu from '../components/layout/TopMenu'
import ParamsPanel from '../components/layout/ParamsPanel'
import MainContent from '../components/layout/MainContent'
import DynamicContent from '../components/content/DynamicContent'
import PageHeader from '../components/layout/PageHeader'

import SelectRegion from '../components/selects/SelectRegion'
import SelectReason from '../components/selects/SelectReason'
import SelectSex from '../components/selects/SelectSex'
import SelectAge from '../components/selects/SelectAge'
import SelectPopulationGroup from '../components/selects/SelectPopulationGroup'

export default {
  name: 'DynAmic',
  components: {
    TopMenu,
    ParamsPanel,
    MainContent,
    DynamicContent,
    SelectRegion,
    SelectReason,
    SelectSex,
    SelectAge,
    SelectPopulationGroup,
    PageHeader,
  },
}
</script>
