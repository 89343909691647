<template>
  <div class="d3-legend">
    <div v-if="items.length" :class="colCount ? 'legend-items-columns ' : 'legend-items'">
      <div
        :class="`legend-item item-${item.id}`"
        v-for="item of items"
        :key="item.id"
        :ref="'legend-' + item.id"
        :style="`--col-count:${colCount}`"
      >
        <div
          class="legend-swatch-border"
          :style="`--opacity:${opacity}; border-color: ${item.color}`"
        >
          <div
            class="legend-swatch"
            :style="`--opacity:${opacity}; background: ${item.color}; border-color: ${item.color}`"
          ></div>
        </div>
        <div class="legend-label" :title="item.name">
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "D3Legend",
  data() {
    return {
      colCount: 1,
      opacity: 0.3,
    };
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    events: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Object,
      default: () => ({
        colCount: 2,
        opacity: false,
      }),
    },
  },
  created() {
    this.colCount = this.options.colCount;
    this.opacity = this.options.opacity ? this.opacity : 1;
  },
  watch: {
    items: {
      handler() {
        this.$nextTick(() => {
          for (const i of this.items) {
            if (this.$refs["legend-" + i.id] && this.$refs["legend-" + i.id][0]) {
              for (const e in this.events) {
                const f = () => {
                  this.events[e](i.id);
                };
                this.$refs["legend-" + i.id][0].removeEventListener(e, f);
                this.$refs["legend-" + i.id][0].addEventListener(e, f);
              }
            }
          }
        });
      },
    },
  },
};
</script>

<style scoped>
.legend-item {
  display: flex;
}

.legend-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  flex-grow: 1;
  margin: 4px 8px 4px 2px;
}

.legend-swatch-border {
  width: 30px;
  min-width: 30px;
  height: 10px;
  margin: 6px;
  border: 1px solid;
}

.legend-swatch {
  width: 28px;
  min-width: 28px;
  height: 8px;
  opacity: var(--opacity);
}

.legend-items {
  font: 12px sans-serif;
  display: flex;
  flex-wrap: wrap;
}

.legend-items-columns {
  font: 12px sans-serif;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.legend-items-columns .legend-item {
  width: calc(100% / var(--col-count));
  min-width: 150px;
}
div >>> .clouded {
  opacity: 0.5;
}
</style>
