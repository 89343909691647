<template>
  <AutocompleteSelect label="Регион" :items="regionItemsAdapter" v-model="value" />
</template>

<script>
import dataProviderMixin from './selectDataProvider'
import AutocompleteSelect from './controlTypes/AutocompleteSelect'

export default {
  mixins: [dataProviderMixin],
  data: () => ({
    paramName: 'regionId',
  }),
  components: {
    AutocompleteSelect,
  },
  computed: {
    /*
      не удается использовать встроенный в миксин метод getItems
      т.к. разные названия полей region — regions
    */
    regionItemsAdapter() {
      return this.getDictionaries.regions
    },
  },
}
</script>
